import { Routes } from '@angular/router';
import { authGuardChildRouteGuard, authGuardRouteGuard } from '@medappsware/maw-auth';
import { environment } from '../environments/environment';
import { CheckPortalComponent } from './components/check-portal/check-portal.component';


export const routes: Routes = [
    {
        path: 'check-portal',
        canActivate: [authGuardRouteGuard],
        canActivateChild: [authGuardChildRouteGuard],
        data: { refreshToken: environment.authApiUrl + 'auth/refresh' },
        component: CheckPortalComponent
    },
    {
        path: '',
        loadChildren: () => import('./components/front-end/front-end.routes').then(m => m.FrontEndRoutes)
    },
    {
        path: '',
        canActivate: [authGuardRouteGuard],
        canActivateChild: [authGuardChildRouteGuard],
        data: { refreshToken: environment.authApiUrl + 'auth/refresh' },
        loadChildren: () => import('./components/maw-template/maw-template.routes').then(m => m.MainTemplateRoutes)
    }

];
